import cn from 'classnames';
import React, { useMemo, useCallback } from 'react';
import { Link } from '@/Framework/Router/Next/Link';
import isEmpty from 'lodash/isEmpty';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import Select from '@/Framework/UI/Molecules/Form/Select/legacy';
import TemporaryAllocationsTable from './TemporaryAllocationsTable';
import StepWrapper from '@/allocate/ui/components/shared/Upload/StepWrapper';
import { Input, IconType } from '@dealroadshow/uikit';
import { isScreenS } from '@dealroadshow/uikit/dist/lib/styles/screen/screen';
import useResponsiveTabs from '@/allocate/ui/hooks/useResponsiveTabs';
import useConfirmAllocations from '@/allocate/ui/components/shared/Upload/ConfirmAllocations/useConfirmAllocations';
import { useUploadContext } from '@/allocate/application/Allocations/Upload/UploadContext';
import { steps, getUploadUrl } from '@/allocate/application/Allocations/Upload/config';
import styles from '@/allocate/ui/components/shared/Upload/ConfirmAllocations/confirmAllocations.scss';
import tabsStyles from '@dealroadshow/uikit/dist/lib/styles/tabs.scss';

const ConfirmAllocations = () => {
  const { tenant, linkedRoadshowId } = useUploadContext();

  const {
    isFetching,
    isFetched,
    selectedClass,
    sortBy,
    sortOrder,
    searchInputValue,
    allocationsClasses,
    collection,
    currencyDisplayNames,
    handleSearch,
    handleSortChange,
    handleClassChange,
  } = useConfirmAllocations();

  const {
    tabsRef,
    tabsWrapperRef,
    isTabsSelectVisible,
    isTabsFetching,
  } = useResponsiveTabs(
    allocationsClasses,
    isScreenS,
  );

  const renderClasses = useCallback(() => {
    if (isTabsSelectVisible) {
      const allocationsClassesOptions = allocationsClasses.map((className) => ({
        value: className,
        label: className,
      }));

      return (
        <Select
          formFieldClassName={ styles.selectWrapper }
          className={ styles.select }
          value={ {
            value: selectedClass,
            label: selectedClass,
          } }
          onChange={ handleClassChange }
          options={ allocationsClassesOptions }
          supportHighlighting={ false }
          simpleValue
          isNarrow
          dataTest="confirmAllocationSelect"
        />
      );
    }

    return allocationsClasses.map((className) => (
      <span
        key={ className }
        onClick={ () => handleClassChange(className) }
        className={ cn(tabsStyles.tab, {
          [tabsStyles.isTabActive]: selectedClass === className,
        }) }
      >
        <span>{ className }</span>
      </span>
    ));
  }, [selectedClass, isTabsSelectVisible, allocationsClasses]);

  const footerContent = useMemo(() => (
    <>
      <Link to={ getUploadUrl(tenant, steps.confirmSecurityDetails, linkedRoadshowId) }>
        <Button
          variant={ variantTypes.text }
          dataTest="confirmAllocationCancelButton"
        >
          Back
        </Button>
      </Link>
      <Link to={ getUploadUrl(tenant, steps.linkAccounts, linkedRoadshowId) }>
        <Button
          variant={ variantTypes.action }
          dataTest="confirmAllocationConfirmButton"
        >
          Confirm
        </Button>
      </Link>
    </>
  ), []);

  return (
    <StepWrapper
      isFetching={ isFetching && !isFetched }
      footer={ footerContent }
    >
      <div className={ styles.tableHeader }>
        <div className={ styles.title }>Allocations</div>
        <div className={ styles.tableFilters }>
          { /* @ts-ignore */ }
          <Input
            formFieldClassName={ cn(styles.searchInput, {
              [styles.withSelect]: isTabsSelectVisible,
            }) }
            iconType={ IconType.filtering }
            value={ searchInputValue }
            placeholder="Filter by Account (Orig)"
            onChange={ handleSearch }
            isNarrow
            isClearable
            dataTest="confirmAllocationInput"
          />
          { (!isEmpty(allocationsClasses) && isTabsSelectVisible) && renderClasses() }
        </div>
      </div>
      <div
        className={ cn(styles.tabsWrapper, {
          [styles.isHidden]: isTabsSelectVisible || isTabsFetching,
        }) }
        ref={ tabsWrapperRef }
      >
        <div className={ styles.tabsWideContainer }>
          <span ref={ tabsRef }>
            { (!isEmpty(allocationsClasses) && !isTabsSelectVisible) && renderClasses() }
          </span>
        </div>
      </div>
      <TemporaryAllocationsTable
        containerClassName={ cn({
          [styles.withoutTabs]: isTabsSelectVisible || isTabsFetching,
        }) }
        collection={ collection }
        selectedClass={ selectedClass }
        isFetching={ isFetching }
        currencyDisplayNames={ currencyDisplayNames }
        onSortChange={ (sortBy, sortOrder) => handleSortChange(sortBy, sortOrder) }
        sortBy={ sortBy }
        sortOrder={ sortOrder }
      />
    </StepWrapper>
  );
};

export default ConfirmAllocations;
