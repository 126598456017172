import { useMemo, useEffect, useState, useCallback } from 'react';
import useRouter from '@/Framework/hooks/useNextRouter';
import { Link } from '@/Framework/Router/Next/Link';
import cn from 'classnames';
import { useUploadContext } from '@/allocate/application/Allocations/Upload/UploadContext';
import { useEnhancedTaggingContext } from '@/Framework/UI/Organisms/EnhancedTagging/EnhancedTaggingContext';
import StepWrapper from '@/allocate/ui/components/shared/Upload/StepWrapper';
import { normalizeDealSizeValue } from '@/Framework/UI/Organisms/EnhancedTagging/Field/DealSize';
import useEnhancedTagging from '@/allocate/ui/components/shared/Upload/EnhancedTagging/useEnhancedTagging';
import { IEnhancedTagging } from '@/allocate/domain/vo/Allocations/Upload/EnhancedTagging';
import { transactionTypes as transactionTypesIds } from '@/dealroadshow/domain/TransactionTypes';
import FinalFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/FinalFormSelect';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import { Tooltip } from '@dealroadshow/uikit';
import validate from '@/allocate/ui/components/shared/Upload/EnhancedTagging/validator';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import EnhancedTagging from '@/Framework/UI/Organisms/EnhancedTagging';
import { steps, getUploadUrl, changeEditingStep, getCancelEditingUrl } from '@/allocate/application/Allocations/Upload/config';
import { TFinalFormRenderProp } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import styles from '@/allocate/ui/components/shared/Upload/EnhancedTagging/enhancedTagging.scss';

function EnhancedTaggingStep() {
  const { push, query: { from } } = useRouter();

  const {
    tenant,
    isEditing,
    dealAllocationId,
    linkedRoadshowId,
  } = useUploadContext();

  const {
    countries,
    sectors,
    industries,
    currencies,
    transactionTypes,
    companies,
    selectedCompany: {
      isFetching: isFetchingSelectedCompany,
      companyName: sponsorName,
      getCompanyNameById,
    },
  } = useEnhancedTaggingContext();

  const {
    getEnhancedTagging,
    saveEnhancedTagging,
    isFetching: isFetchingFormData,
    enhancedTaggingData,
    isSubmitting,
  } = useEnhancedTagging();

  const [isFetching, setIsFetching] = useState(true);

  const { isFetching: isFetchingCountries } = countries;
  const { isFetching: isFetchingCurrencies } = currencies;
  const { isFetching: isFetchingIndustries } = industries;
  const { isFetching: isFetchingTransaction, getTransactionTypes } = transactionTypes;

  useEffect(() => {
    if (dealAllocationId) {
      getEnhancedTagging();
      getTransactionTypes();
    }
  }, [dealAllocationId]);

  useEffect(() => {
    if (
      isFetchingCountries ||
      isFetchingCurrencies ||
      isFetchingIndustries ||
      isFetchingTransaction ||
      isFetchingSelectedCompany ||
      isFetchingFormData
    ) {
      setIsFetching(true);
    } else {
      setIsFetching(false);
    }
  }, [
    isFetchingCountries,
    isFetchingCurrencies,
    isFetchingIndustries,
    isFetchingTransaction,
    isFetchingSelectedCompany,
    isFetchingFormData,
  ]);

  useEffect(() => {
    if (enhancedTaggingData.sponsorId) {
      getCompanyNameById(enhancedTaggingData.sponsorId);
    }
  }, [enhancedTaggingData]);

  const onSubmit = useCallback(({ enhancedTagging }: { enhancedTagging: IEnhancedTagging }) => {
    saveEnhancedTagging(enhancedTagging);
  }, [dealAllocationId, linkedRoadshowId]);

  const onBackButtonClicked = useCallback(() => {
    if (isEditing) {
      changeEditingStep(steps.dealManagers, dealAllocationId, from?.toString());
    } else {
      push(getUploadUrl(tenant, steps.dealManagers));
    }
  }, [isEditing, dealAllocationId]);

  const isSectorVisible = useCallback((id: string) => (
    id === transactionTypesIds.BondSecuritization || id === transactionTypesIds.NonDealRoadshow
  ), []);

  const initialValues = useMemo(() => ({
    enhancedTagging: {
      ...enhancedTaggingData,
      sponsorName,
      dealSize: normalizeDealSizeValue(enhancedTaggingData.dealSize),
      underwriters: enhancedTaggingData.underwriters.length ?
        enhancedTaggingData.underwriters :
        [{ lead: false, name: '' }],
    },
  }), [enhancedTaggingData, sponsorName, dealAllocationId]);

  const transactionTypeOptions = useMemo(() => transactionTypes.collection.map((option) => ({
    value: option.id,
    label: option.name,
  })), [transactionTypes, dealAllocationId]);

  const isDisabled = useMemo(() => !!linkedRoadshowId, [linkedRoadshowId]);

  const renderForm: TFinalFormRenderProp = useCallback(({ values, invalid, submitFailed }, { Field, FieldArray }) => (
    <>
      <div className={ cn(styles.scrollContainer, {
        [styles.modifyDisabled]: isDisabled,
      }) }
      >
        <div className={ styles.formWrapper }>
          <Field
            label="transaction type"
            name="enhancedTagging.transactionTypeId"
            placeholder="Transaction Type"
            className={ styles.formInputWrapper }
            // @ts-ignore
            component={ FinalFormSelect }
            clearable={ false }
            simpleValue
            asterisk
            options={ transactionTypeOptions }
            disabled={ isDisabled }
            dataTest="enhancedTaggingTransactionType"
          />
          <EnhancedTagging
            isSectorVisible={ isSectorVisible(values.enhancedTagging.transactionTypeId) }
            countries={ countries }
            sectors={ sectors }
            industries={ industries }
            companies={ companies }
            currencies={ currencies }
            fieldComponent={ Field }
            fieldArrayComponent={ FieldArray }
            underwritersWrapperClassName={ cn(styles.underwritersWrapper, {
              [styles.disabled]: isDisabled,
            }) }
            underwritersRowClassName={ styles.underwritersRow }
            wrapperClassName={ styles.enhancedTaggingWrapper }
            isDisabled={ isDisabled }
          />
        </div>
      </div>
      { isDisabled && (
        <div className={ styles.modifyMobile }>
          <Link to={ `/roadshow/${ linkedRoadshowId }/edit` } target="_blank">
            <Button
              variant={ variantTypes.outline }
              dataTest="enhancedTaggingAllocateModifyButton"
            >
              Modify on Linked Roadshow
            </Button>
          </Link>
          <p>
            Changes to Enhanced Tagging must be done through the roadshow
          </p>
        </div>
      ) }
      <div className={ styles.buttons }>
        <Button
          variant={ variantTypes.text }
          onClick={ onBackButtonClicked }
          dataTest="enhancedTaggingAllocateBackButton"
        >
          Back
        </Button>
        <div>
          { isEditing && (
            <Link to={ getCancelEditingUrl(tenant, from?.toString(), dealAllocationId) }>
              <Button
                variant={ variantTypes.text }
                dataTest="dealManagersCancelButton"
                className={ styles.cancelButton }
              >
                Cancel
              </Button>
            </Link>
          ) }
          { isDisabled && (
            <Tooltip content="Changes to Deal Managers must be done through the roadshow">
              <Link
                to={ `/roadshow/${ linkedRoadshowId }/edit` }
                target="_blank"
                className={ styles.modifyButton }
              >
                <Button
                  variant={ variantTypes.outline }
                  dataTest="enhancedTaggingAllocateModifyButton"
                >
                  Modify on Linked Roadshow
                </Button>
              </Link>
            </Tooltip>
          ) }
          <Button
            type="submit"
            variant={ variantTypes.action }
            dataTest="enhancedTaggingAllocateSubmitButton"
            disabled={ isSubmitting || (submitFailed && invalid) }
          >
            { isEditing ? 'Save' : 'Import' }
          </Button>
        </div>
      </div>
    </>
  ), [
    dealAllocationId,
    linkedRoadshowId,
    countries,
    sectors,
    industries,
    companies,
    currencies,
    isDisabled,
    isEditing,
    isSubmitting,
  ]);

  return (
    <StepWrapper isFetching={ isFetching }>
      <div className={ styles.title }>
        Enhanced Tagging for Analytics
      </div>
      <FinalForm
        initialValues={ initialValues }
        className={ styles.form }
        onSubmit={ onSubmit }
        render={ renderForm }
        validate={ validate }
        name="enhancedTaggingAllocateForm"
        keepDirtyOnReinitialize
        dataTest="enhancedTaggingAllocateForm"
      />
    </StepWrapper>
  );
}

export default EnhancedTaggingStep;
