import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import absSectors from '@/dealroadshow/application/actions/absSector/reducer';
import industries from '@/dealroadshow/application/actions/industry/reducer';
import roadshowReducer from '@/dealroadshow/application/actions/reducer';
import Upload from '@/Framework/UI/Organisms/Upload';
import whitelist from '@/dealroadshow/application/actions/roadshowForm/whitelist/reducer';
import blacklist from '@/dealroadshow/application/actions/blacklist/reducer';
import entryCodes from '@/dealroadshow/application/actions/entryCodes/reducer';
import timeZone from '@/Framework/TimeZone/State/Redux/reducer';
import roadshow from '@/dealroadshow/application/actions/roadshowForm/reducer';
import accountType from '@/dealroadshow/application/actions/accountType/reducer';
import transactionTypes from '@/dealroadshow/application/actions/transactionType/reducer';
import filters from '@/Framework/UI/Organisms/Filters/reducer';
import checkboxes from '@/Framework/UI/Organisms/Filters/Checkboxer/reducer';
import dmPortal from '@/dmPortal/application/actions/reducer';
import companies from '@/users/application/actions/companies/reducer';
import currencies from '@/dmPortal/application/actions/currencies/reducer';
import countries from '@/dealroadshow/application/actions/countries/reducer';
import tenant from '@/Framework/Tenant/State/Redux/reducer';
import { routerReducer } from '@dealroadshow/connected-next-router';
import containerReducer from '@/Framework/DI/containerReducer';

export default combineReducers({
  router: routerReducer,
  container: containerReducer,
  match: (state) => state || null,
  companies,
  currencies,
  countries,
  transactionTypes,
  accountType,
  industries,
  absSectors,
  roadshow: roadshowReducer,
  dmPortal,
  form: formReducer,
  upload: Upload.reducers,
  roadshowForm: combineReducers({
    whitelist,
    blacklist,
    roadshow,
    entryCodes,
  }),
  timeZone,
  filters,
  checkboxes,
  tenant,
});
